.pilares
  h2
    font-size: 1.3rem
    color: #fd7e01
    padding: 2rem 0 0


    position: relative
    &:after
      content: ''
      width: 80%
      left: 20%
      bottom: -1rem
      height: 2px
      position: absolute
      background: #fd7e01
  .column.boxes
    display: flex !important
    justify-content: center !important
    margin: auto !important
    clear: both !important
  h3
    margin: 2rem auto 0 !important
    font-size: 1.3rem
    color: rgba(0,0,0,0.7) !important
    img
      margin-bottom: 1rem
  