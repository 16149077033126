.clientes
  .title
    padding: 1rem 2rem
    color: #ff9209
    font-size: 3rem
    font-weight: bolder !important
.clientes
  .segment
    box-shadow: 0 0 0 0 !important
    border-color: rgba(0,0,0,0.04) !important
    .header
      color: #70786f !important
      .content
        font-size: 1.3rem !important