@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600,700,800&display=swap");
::-webkit-scrollbar-track {
    background-color: #f0f0f0;
}

::-webkit-scrollbar {
    width: 6px;
    background-color: #f0f0f0;
}

::-webkit-scrollbar-thumb {
    background-color: #fc9100;
}

body, * {
    font-family: 'Open Sans', sans-serif;
}



@media only screen and (max-width: 1049px) {
    .ui.container {
        margin-left: 0px !important;
        margin-right: 0px !important;
    }
}

@media only screen and (max-width: 1150px) {
    .ui.container {
        width: 98%;
    }
}

.ui.divider {
    opacity: 0.2 !important;
}

.p-0 {
    padding: 0 !important;
}

.reset {
    padding: 0px !important;
    margin: 0px !important;
}

.ui.grid {
    padding: 0 !important;
    margin: 0 !important;
}

.grid.info {
    padding: 2rem  !important;
    margin: auto !important;
    justify-content: center !important;
}

.grid.info .header {
    padding: 0 !important;
    margin: 0 !important;
}

.grid.info .column {
    clear: both;
    position: relative;
    padding: 1rem 1rem !important;
}

.grid.info .column .image {
    mix-blend-mode: luminosity !important;
    border-radius: 10px;
    margin: 1rem 0;
}

.grid.info .column .header {
    color: #fc7100 !important;
    font-size: 1.3rem;
    padding: 0.5rem 0 0 !important;
    clear: both !important;
}

.grid.info .column .sub {
    font-weight: bold;
    color: #70786f !important;
    padding: 0 !important;
    font-variant: small-caps;
    font-size: 1rem !important;
}

.grid.info .column p {
    margin: 0.5rem;
    font-size: 1rem !important;
    color: #8e8e8e !important;
}

.informativo {
    font-size: 1.3rem;
    padding: 1rem !important;
    color: #16242b !important;
    background: #fafafa;
}

.informativo .segment {
    box-shadow: 0 0 1px 2px #fafafa !important;
}

.informativo h2.ui.header {
    font-size: 2rem !important;
    padding: 2rem 0;
    font-weight: normal !important;
    color: #70786f;
}

.informativo h3.header {
    font-weight: bold !important;
    margin: auto 0 !important;
    font-size: 2rem !important;
    display: flex !important;
    color: #70786f;
}

.informativo h3.header .sub {
    letter-spacing: 1px;
    font-size: .8rem !important;
    font-weight: normal;
    color: #70786f;
    opacity: 0.7;
}

.informativo h3.header .icon {
    margin: auto 1rem auto 0 !important;
    font-size: 2.6rem;
    font-weight: normal !important;
    color: #fc7100;
}

.informativo .flex {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.informativo .segment {
    margin: 1rem;
    flex-wrap: wrap;
    display: flex;
    height: 100px;
    border: 0 !important;
}

.informativo .segment:first-child {
    margin-top: 1rem;
}

@media only screen and (max-width: 1049px) {
    .informativo .segment {
        width: 100% !important;
    }
}

footer {
    padding: 3rem 0 0 !important;
    display: flex !important;
    justify-content: center;
    position: relative;
    z-index: 555;
}

.rodape .container:before {
    margin: 2rem 1rem;
    background: #fafafa;
    width: 100% !important;
    height: 2px !important;
    content: ' ' !important;
}

@media only screen and (max-width: 1049px) {
    footer {
        padding: 1rem 2rem !important;
    }
}

footer .ui.vertical.menu .item:before {
    background: rgba(0, 0, 0, 0) !important;
}

footer .copyright {
    display: block !important;
    width: 100% !important;
    height: auto;
    font-size: 0.8rem;
}

footer .logo {
    display: block !important;
    width: 100% !important;
    height: 50px !important;
    background-repeat: no-repeat !important;
    background-size: 90px;
    background-repeat: repeat-y;
}

footer .column {
    font-size: 1rem !important;
    color: #5c6265 !important;
}

footer .column .space {
    margin-top: 0.5rem;
}

footer .column .item {
    color: #5c6265 !important;
}

@media only screen and (max-width: 1049px) {
    footer .column .item {
        font-size: 1rem !important;
    }
    footer .column .item a {
        padding: 0.5rem 0rem;
    }
}

footer p {
    padding: 0.2rem 0 !important;
    margin: 0 !important;
    color: #5c6265 !important;
}

footer .telefone {
    color: #879087 !important;
    display: block;
}

footer:nth-of-type(1) {
    color: #656b65 !important;
}

footer .telefone {
    display: block;
    font-weight: bold;
    color: #fc6b00 !important;
}

footer .header {
    color: #1b641a !important;
    margin: 0 !important;
    padding: 0.1rem 0 !important;
    font-size: 1.2rem !important;
    font-weight: bold !important;
}

@media only screen and (max-width: 1049px) {
    footer .header {
        font-size: 1.4rem !important;
        padding: 1rem 0 !important;
    }
}

footer b {
    display: block !important;
    color: #2a3135 !important;
}

footer .menu {
    background: rgba(0, 0, 0, 0) !important;
    box-shadow: 0 0 0 0  !important;
    border: 0 !important;
}

.ui.modal > .close {
    top: 1.2rem !important;
    right: 1rem !important;
    border-radius: 50px;
    background: #bcbcbc !important;
    font-size: 1rem !important;
}

@media only screen and (max-width: 1049px) {
    .ui.modal > .close {
        top: 0.5rem !important;
        color: #fff;
    }
}

.referenciados .grid {
    justify-content: center;
}

.referenciados .grid .column {
    position: relative !important;
}

@media only screen and (max-width: 620px) {
    .referenciados .grid .column {
        width: 100% !important;
    }
}

.referenciados .ui.segment {
    border: 0 !important;
    box-shadow: 0 2px 2px 3px rgba(0, 0, 0, 0.02) !important;
}

.referenciados .ui.segment .image {
    display: flex;
}

.referenciados .ui.segment .image img {
    margin: auto;
}

.referenciados .ui.segment .content .header {
    font-size: 1.2rem !important;
    margin-bottom: 1rem;
    color: rgba(0, 0, 0, 0.6);
}

.referenciados .ui.segment .content a {
    font-size: 0.9rem;
    color: #6b6b6b;
    margin-bottom: 0.6rem;
}

.referenciados .ui.segment .content a div {
    padding: 0.5rem 0;
}

.referenciados .ui.segment .content a .icon {
    font-size: 1rem;
    color: #f26806;
}

.referenciados .ui.segment .content a:hover {
    color: #f26806;
}

.referenciados .ui.segment .pgcanais {
    position: absolute !important;
    right: 0 !important;
    top: 1.5rem;
    border-radius: .4rem 0 0 .4rem;
    padding: 0.4rem 0.6rem 0.2rem;
    background: #ff8e16  !important;
    cursor: pointer;
}

.referenciados .ui.segment .pgcanais:hover {
    color: #b55c0d !important;
}

.hide {
    display: none;
}

.telefones .column {
    padding: 1rem 0.3rem  !important;
}

.telefones .segment {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
    border: 1px solid rgba(0, 0, 0, 0.05);
}

.telefones .segment .numeros {
    display: flex;
}

.telefones .segment .numeros .ui.header {
    padding-right: 1rem;
}

.telefones .segment .numeros .ui.header a {
    color: #396536;
}

.telefones .segment .ui.header {
    padding-bottom: 0.5rem !important;
    margin: 0 !important;
    color: #151e43;
    font-size: 1rem;
}

.telefones .segment .ui.header a:hover {
    color: #ff8c05;
}

.ligue a {
    cursor: pointer;
    display: block !important;
    border: 1px solid rgba(255, 255, 255, 0.3) !important;
    border-radius: .3rem;
    font-size: 1.1rem;
    color: white;
    clear: both !important;
    padding: 0.5rem 1rem;
    margin: .2rem 0 !important;
}

.ligue a:hover {
    color: white;
    border-color: #fc7d00 !important;
    background: #fc7d00 !important;
}

@media only screen and (max-width: 620px) {
    .ligue {
        display: none !important;
    }
}

.contato .form label {
    color: rgba(0, 0, 0, 0.6) !important;
}

.contato .header .content {
    padding-left: 2rem;
    color: #70786f;
}

footer .menu a {
    font-size: 1rem !important;
}

.estilo {
    font-size: 1.1rem;
    font-weight: bold;
    color: #fff !important;
    margin: 0 0 0 0.8rem !important;
    border-radius: 50em !important;
    padding: 1rem 2rem !important;
    background: #fc7d00 !important;
    background: -moz-linear-gradient(45deg, #fc7d00 0%, #fc7d00 59%, #fc9500 77%, #fc9500 100%) !important;
    background: -webkit-linear-gradient(45deg, #fc7d00 0%, #fc7d00 59%, #fc9500 77%, #fc9500 100%) !important;
    background: linear-gradient(45deg, #fc7d00 0%, #fc7d00 59%, #fc9500 77%, #fc9500 100%) !important;
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fc7d00', endColorstr='#fc9500',GradientType=1 ) !important;
}

@media only screen and (max-width: 1049px) {
    .estilo {
        order: -1 !important;
    }
}

.flex {
    display: flex;
}

.conteudo {
    text-align: justify !important;
    padding: 1rem !important;
    font-size: 1.2rem;
}
.conteudo p {
    color: rgba(0,0,0,.6)!important;
}
.conteudo .ui.header .sub.header {
    text-align: justify !important;
    padding: 1rem 0 !important;
}

.conteudo ol.ui.list li {
    position: relative;
    padding: 0 0 1rem 25px !important;
}

.conteudo ol.ui.list li::before {
    height: 25px;
    width: 25px;
    line-height: 25px;
    color: #fff;
    font-weight: bold;
    text-align: center;
    position: absolute !important;
    left: 0 !important;
    border-radius: 25px;
    background: #fc7d00 !important;
    background: -moz-linear-gradient(45deg, #fc7d00 0%, #fc7d00 59%, #fc9500 77%, #fc9500 100%) !important;
    background: -webkit-linear-gradient(45deg, #fc7d00 0%, #fc7d00 59%, #fc9500 77%, #fc9500 100%) !important;
    background: linear-gradient(45deg, #fc7d00 0%, #fc7d00 59%, #fc9500 77%, #fc9500 100%) !important;
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fc7d00', endColorstr='#fc9500',GradientType=1 ) !important;
}

.load {
    height: 100vh;
}

.load .logo {
    width: 200px;
    margin: auto;
    position: absolute;
    top: 30%;
    left: 0;
    right: 0;
}

.spinner {
    animation: rotate 2s linear infinite;
    z-index: 2;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -20px 0 0 -20px;
    width: 40px;
    height: 40px;
}

.spinner .path {
    stroke: #ed6926;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 150;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -35;
    }
    100% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -124;
    }
}
.menu.right .icon, .sidebar .icon {
    color: #fc9200 !important;
}
.pagina .menu.right .icon, .sidebar .icon {
    color: #fff !important;
}
.menu.right .router-link-active.router-link-exact-active, .menu.right .dropdown.router-link-active, .sidebar .router-link-active.router-link-exact-active, .sidebar .dropdown.router-link-active {
    background: #f0f0f0 !important;
}





header .nav-menu.pagina .menu.right .item {
    color: #353b34 !important;
    text-shadow: none;
}

header .nav-menu.pagina .menu.right .estilo {
    color: white !important;
}

header .nav-menu.pagina .menu.right .estilo:hover {
    background: rgba(0, 0, 0, 0.03);
}

header .nav-menu.pagina .menu.right .icon {
    color: #5d655c !important;
}

header .nav-menu .ui.container {
    padding: 1rem !important;
}



header .nav-menu .menu.right:after {
    top: 0;
    height: 9rem;
    z-index: -1;
    position: absolute;
    content: '';
}

header .nav-menu .menu.right .item {
    padding: 1rem;
    color: #2d4017 !important;
    margin: 0.5rem;
    border-radius: 33rem;
}

header .nav-menu .menu.right .item.estilo {
    color: #fff !important;
}

header .nav-menu .menu.right .item .icon {
    font-size: 1.8rem;
    margin: 0 !important;
    color: rgba(255, 255, 255, 0.8);
}

header .nav-menu .menu.right .item:hover {
    background: rgba(0, 0, 0, 0.06);
}

header .nav-menu .menu.right .item:hover .icon {
    color: #fc9500   !important;
}

header .nav-menu .menu .item > img:not(.ui) {
    width: 6em !important;
}

header .nav-menu .menu .logo {
    font-size: 1.5rem;
    padding: 0 !important;
}

header .nav-menu .menu .logo:hover {
    background: rgba(0, 0, 0, 0) !important;
}

header .sticky {
    background: #FFF;
    padding: 0;
}

header .sticky .menu.right .item {
    color: #353b34 !important;
    text-shadow: none;
}

header .sticky .menu.right .item:hover {
    background: rgba(0, 0, 0, 0.03) !important;
}

header .sticky .menu.right.right .estilo {
    color: #fff !important;
}

header .sticky .menu.right.right .icon {
    color: #5d655c  !important;
}

.ui.secondary.menu .dropdown.item > .menu, .ui.secondary.menu .dropdown.item > .menu {
    margin-top: 0 !important;
    background: #fff !important;
    border-color: rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
}

.secondary .ui.dropdown .menu {
    border-radius: 0 !important;
    padding: 1rem;
    background: #fff !important;
    font-size: 1.1rem;
}

.secondary .ui.dropdown .menu .item {
    border-radius: 0 !important;
    padding: 0.2rem !important;
}

.secondary .ui.dropdown.simple.item {
    border-radius: 20rem !important;
}

.secondary .ui.dropdown.simple.item .menu {
    background: rgba(0, 0, 0, 0) !important;
}

.secondary .ui.dropdown.simple.item .menu .item {
    background: #f8f8f8  !important;
}

.secondary .ui.dropdown.simple.item .menu .item:hover {
    background: #fff !important;
    box-shadow: inset 3px 0 0 0 #ff952d  !important;
    color: #fc7d00 !important;
    z-index: 54;
}

.secondary .ui.dropdown.simple.item .menu .item:first-of-type {
    border-radius: 8px 8px 0 0 !important;
}

.secondary .ui.dropdown.simple.item .menu .item:first-of-type:hover {
    box-shadow: inset 3px 0 0 0 #ff952d  !important;
    border-radius: 0 0 0 0 !important;
    border-radius: 0 8px 0 0   !important;
}

.secondary .ui.dropdown.simple.item .menu .item:first-of-type:after {
    position: absolute !important;
    top: -10px;
    z-index: -1 !important;
    left: 1rem;
    content: '' !important;
    border-style: solid !important;
    border-width: 0 8px 10px 8px;
    border-color: transparent transparent #f8f8f8 transparent;
}

.secondary .ui.dropdown.simple.item .menu .item:last-of-type {
    border-radius: 0 0 8px 8px !important;
    box-shadow: 0 1rem 1rem 0 rgba(0, 0, 0, 0.2) !important;
}

.secondary .ui.dropdown.simple.item .menu .item:last-of-type:hover {
    box-shadow: inset 3px 0 0 0 #ff952d, 0 1rem 1rem 0 rgba(0, 0, 0, 0.2) !important;
    border-radius: 0 0  8px  0 !important;
}

header .swiper {
    width: 100%;
  }

  header .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }

  header .swiper-slide img {
    display: block;
    width: 100%;
    height: 640px;
    object-fit: cover;
  }
  header .slider {
    position: relative !important;
    height: auto;
    background-position: 0 150px !important;
    background-size: 150% !important;
}

header .slider .tns-outer {
    z-index: 5 !important;
    position: relative;
}


header .slider .conteudo-texto {
    margin: auto;
    top: 7rem;
    bottom: 0;
    position: absolute;
    z-index: 7;
    max-height: 350px;
    padding: 2rem;
    left: 2rem;
    right: 2rem;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;

    @media (max-width: 800px)
    {
       margin: 0;
       .texto {
        max-width: 100% !important
    }
    }
}

header .slider .conteudo-texto .button {
    padding: 1.2rem 1.8rem;
    color: #ff952d;
    font-size: 1.3rem;
    border-radius: 99rem;
    background: #fff;
}

header .slider .conteudo-texto .button.secundario {
    border-color: white;
}

header .slider .conteudo-texto .button:hover {
    background: #ff952d;
    color: #fff;
}

header .slider .conteudo-texto .texto {
    margin: auto 0;
    padding: 1rem 0 4rem;
    max-width: 75%;
}

header .slider .conteudo-texto .texto.menor h2 {
    font-size: 1.7rem;
}

header .slider .conteudo-texto .texto.menor .sub {
    font-size: 1.2rem;
}

header .slider .conteudo-texto .header {
    font-size: 3.5rem;
    color: #fc7d00;
    max-width: 750px;
    position: relative;
    text-align: left;
}

header .slider .conteudo-texto .sub {
    font-size: 1.6rem;
    text-align: left;
    color: #2d4017;
    z-index: 60;
    font-weight: normal !important;
    position: relative;
    @media (max-width: 600px)
    {
        font-size: 1.2rem;
    }
}
.parallax-bg {
    position: absolute !important;
    left: 0;
    top: 0;
    width: 130%;
    height: 640px;
    z-index: 1;

  }
  #tsparticles > canvas {
    max-height: 640px !important;
    position: absolute !important;
  }
  .swiper-pagination  {
    z-index: 99 !important;
  }
  header .swiper-slide img {
    min-height: 660px;
        margin: 0 auto;
        position: relative;
        opacity: 1;
        background-position: center !important;
        bottom: 0;
  }
  .efeitoslide {
    min-height: 660px;
    background: -webkit-gradient(linear,right top,left bottom,color-stop(36%,#fff),to(#ed9239));
    width: 100%;
    mix-blend-mode: hard-light;
    position: absolute;
    top: 0;
    z-index: 10;
    opacity: 0.2;
  }


  .efeitoslide2{
    min-height: 660px;
    background: -webkit-gradient(linear,left top,right bottom,color-stop(36%,#fff),to(#b55900));
    width: 100%;
    mix-blend-mode: hard-light;
    position: absolute;
    top: 0;
    z-index: 56;
    opacity: 0.8;
  }


.efeito::before{
    content: '';
    mix-blend-mode: hard-light;
    position: absolute;
    background: linear-gradient(60deg,  #ffffff 36% #b55900 78%,#ed9239 100%);
    opacity: 0.3;
    top: 0;
    width: 100%;
    height: 100%;
  }
.swiper-button-next {
    z-index: 999;
    }
    .swiper-button-prev {
        z-index: 999;
        }
        .menu.right,
        .sidebar {
          .icon {
            color: #fc9100 !important;
          }
          .router-link-active.router-link-exact-active,
          .dropdown.router-link-active {
            background: #f0f0f0 !important;
          }
        }
        header {
          .nav-menu {
            right: 0;
            left: 0;
            padding: 1rem 0;
            width: 100%;
            position: absolute;
            z-index: 58 !important;
            top: 0;
            &.pagina {
              position: relative;
              background: #fff;
              .menu {
                z-index: 9999;
                &.right {
                  .item {
                    color: #353b34 !important;
                    text-shadow: none;
                  }
                  .estilo {
                    color: white !important;
                    &:hover {
                      background: rgba(0,0,0,0.03);
                    }
                  }
                  .icon {
                    color: #5d655c !important;
                  }
                }
              }
            }
            .ui.container {
              padding: 1rem !important;
            }
            .menu {
              .item {
                cursor: pointer;
                font-size: 1.1rem;
                margin: 0 1rem;
                color: #2d4017 !important;
                .icon {
                  margin: 0 0.4rem 0 0 !important;
                }
                &.active {
                  background-color: white !important;
                  color: #396536 !important;
                }
              }
              &.right {
                &:after {
                  top: 0;
                  height: 9rem;
                  z-index: -1;
                  position: absolute;
                  content: '';
                }
                .item {
                  padding: 1rem;
                  color: #2d4017 !important;
                  margin: 0.5rem;
                  border-radius: 33rem;
                  &.estilo {
                    color: #fff !important;
                  }
                  .icon {
                    font-size: 1.8rem;
                    margin: 0 !important;
                    color: rgba(255,255,255,0.8);
                  }
                  &:hover {
                    background: rgba(0,0,0,0.06);
                    .icon {
                      color: #fc9500 !important;
                    }
                  }
                }
              }
              .item > img:not(.ui) {
                width: 6em !important;
              }
              .logo {
                font-size: 1.5rem;
                padding: 0 !important;
                &:hover {
                  background: rgba(0,0,0,0) !important;
                }
              }
            }
          }
          .sticky {
            background: #FFF;
            padding: 0;
            z-index: 998 !important;

            .menu.right {
              .item {
                color: #353b34 !important;
                text-shadow: none;
                &:hover {
                  background: rgba(0,0,0,0.03) !important;
                }
              }
              &.right {
                .estilo {
                  color: #fff !important;
                }
                .icon {
                  color: #5d655c !important;
                }
              }
            }
          }
        }
        .ui.secondary.menu .dropdown.item > .menu,
        .ui.secondary.menu .dropdown.item > .menu {
          margin-top: 0 !important;
          background: #fff !important;
          border-color: rgba(0,0,0,0);
          box-shadow: 0 0 0 0 rgba(0,0,0,0) !important;
        }
        .secondary {
          .ui.dropdown {
            .menu {
              border-radius: 0 !important;
              padding: 1rem;
              background: #fff !important;

              font-size: 1.1rem;
              .item {
                border-radius: 0 !important;
                padding: 0.2rem !important;
              }
            }
            &.simple.item {
              border-radius: 20rem !important;
              .menu {
                background: rgba(0,0,0,0) !important;
                .item {
                  background: #f8f8f8 !important;
                  &:hover {
                    background: #fff !important;
                    box-shadow: inset 3px 0 0 0 #ff952d !important;
                    color: #fc7d00 !important;
                    z-index: 54;
                  }
                  &:first-of-type {
                    border-radius: 8px 8px 0 0 !important;
                    &:hover {
                      box-shadow: inset 3px 0 0 0 #ff952d !important;
                      border-radius: 0 0 0 0 !important;
                      border-radius: 0 8px 0 0 !important;
                    }
                    &:after {
                      position: absolute !important;
                      top: -10px;
                      z-index: -1 !important;
                      left: 1rem;
                      content: '' !important;
                      border-style: solid !important;
                      border-width: 0 8px 10px 8px;
                      border-color: transparent transparent #f8f8f8 transparent;
                    }
                  }
                  &:last-of-type {
                    border-radius: 0 0 8px 8px !important;
                    box-shadow: 0 1rem 1rem 0 rgba(0,0,0,0.2) !important;
                    &:hover {
                      box-shadow: inset 3px 0 0 0 #ff952d  , 0 1rem 1rem 0 rgba(0,0,0,0.2) !important;
                      border-radius: 0 0  8px  0 !important;
                    }
                  }
                }
              }
            }
          }
        }
.conteudo .ui.header {
            bottom: 1rem;
            color: #fc7d00;
            font-site: 2rem !important;
            font-wieght: bold !important;
            background: #fff;
            width: 100%;
        }

        .CookieConsent {
            flex-direction: column !important;
            padding: 1rem;
        }
        .CookieConsent div {
           flex:0 !important ;
            flex-direction: column !important;
        }
.CookieConsent a {
    color: #fc7d00 !important;
    font-weight: 600
}
.redes {
    padding: 1rem 0;
}
.pushable:not(body) {
      transform: none !important;
}
.ui.left.visible.sidebar, .ui.right.visible.sidebar {
    position: fixed !important;
}

.menu-box {
    position: fixed;
    left: 10px;
    display: flex;
    top: -120px;
    bottom: 0;
}
.menu-box ul {
    list-style: none;
    padding-left: 0 !important;
    margin-left: 0 !important;
    
}
.menu-box #pillar-page-sections {
    z-index: 999;
    margin: auto;
    height: auto;
    list-style: none;
}
.menu-box #pillar-page-sections li  {
    position: relative !important;
}

.menu-box #pillar-page-sections li:after  {
    background: red;
    content: '';
    position: absolute;
    height: 15px !important;
    border-radius: 10px;
}

.menu-box li
{
text-align:center;
    padding: .2rem 0;
    width: 20px;
    font-size: 25px;
    cursor: pointer;
  
}
.menu-box ul .link span {
      color: rgba(0,0,0,0.1) !important
}
.menu-box ul .link.active span {
    
      color:  rgb(252, 149, 0) !important
}
.menu-box .link.active {
    font-size: 2rem !important;

}
@media only screen and (max-width: 1049px) {
    .conteudo {
        padding: 1.5rem !important;
    }
}