
.quemsomos
  .video
    padding: 2rem
    max-width: 520px
    width: 100%
    height: 325px
    position: relative
    margin: auto
    .play
      position: absolute
      top: 6rem
      cursor: pointer
      color: rgba(255,255,255,1)
      text-shadow: 0 1rem 2rem rgba(0,0,0,0.8)
      bottom: 0
      right: 0
      left: 0
      margin: auto
      font-size: 6rem
      opacity: .7
      transition: 0.5s
      &:hover
        opacity: 1
    img
      border-radius: 1rem !important
  .texto
    display: block
    margin: auto
    padding: 2rem
    .ui.header
      font-size: 2.8rem
      line-height: 2.8rem
      padding: 2rem
      color: #fc7100
      .sub
        line-height: 1.6rem
        margin-top: 1rem
        font-size: 1.2rem
        color: #70786f
