
.tabs
    background: #fff
    padding: 2rem 0
    .flex
        display: flex !important
        min-height: 300px !important
    .segundavia
        margin: auto !important
        padding: 1.5rem !important
        max-width: 500px
        .input
            background: #fff
            padding: .8rem
            display: flex
            margin: 1rem 0
            font-size: 1.3rem
            border-radius: 55rem !important
            border: 0 !important
            box-shadow: 0 0 2px 1px #ebebeb
            input
                height: 30px !important
                margin: 0 0.5rem
                border: 0px
        .button
            font-size: 1.2rem
            padding: 1rem 2rem !important
            margin: 1rem auto 0
            display: block
            font-weight: normal
            background: rgb(252,125,0) !important
            background: -moz-linear-gradient(45deg, rgba(252,125,0,1) 0%, rgba(252,125,0,1) 59%, rgba(252,149,0,1) 77%, rgba(252,149,0,1) 100%) !important
            background: -webkit-linear-gradient(45deg, rgba(252,125,0,1) 0%,rgba(252,125,0,1) 59%,rgba(252,149,0,1) 77%,rgba(252,149,0,1) 100%) !important
            background: linear-gradient(45deg, rgba(252,125,0,1) 0%,rgba(252,125,0,1) 59%,rgba(252,149,0,1) 77%,rgba(252,149,0,1) 100%) !important
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fc7d00', endColorstr='#fc9500',GradientType=1 ) !important
            border-radius: 55rem !important
            span
              margin-left: .7rem
            &:hover
              background: rgb(252,109,0)!important
              background: -moz-linear-gradient(45deg,  rgba(252,109,0,1) 0%, rgba(252,109,0,1) 59%, rgba(252,130,0,1) 77%, rgba(252,130,0,1) 100%)!important
              background: -webkit-linear-gradient(45deg,  rgba(252,109,0,1) 0%,rgba(252,109,0,1) 59%,rgba(252,130,0,1) 77%,rgba(252,130,0,1) 100%)!important
              background: linear-gradient(45deg,  rgba(252,109,0,1) 0%,rgba(252,109,0,1) 59%,rgba(252,130,0,1) 77%,rgba(252,130,0,1) 100%)!important
              filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fc6d00', endColorstr='#fc8200',GradientType=1 )!important
    .header
        font-weight: bold
        color: #fc7100
        padding-bottom: 0 !important
        font-size: 2rem !important
        .sub
            padding-top: 0.7rem !important
            font-size: 1.1rem !important
            color: #70786f !important
    .menu
        justify-content: center
        flex-wrap: wrap
        padding: 2rem
        box-shadow: 0 1px 0 0 #fff!important
        max-width: 980px
        margin: auto !important
        .item
            font-size: 1.2rem
            color: #4b4743 !important
            border-radius: 20rem !important
        .active
            background: #fc8c2b !important
            color: #fff !important