.informativo
  .column
  .card
    .label , p
        transition: color 2s
    cursor: pointer
    margin: 1rem
    height: 240px
    border-radius: 5px
    padding: 1rem 0px
    position: relative
    clear: both
    img
      width: 100%
      z-index: 1
      top: 0
      opacity: .9
      border-radius: 5px
      position: absolute
      height: 240px
      object-fit: cover
    &:hover
      .box
        background: rgba(0,0,0,0)
      &::after
        height: 70%
      .label
        &:after
          width: 100%
      img
        opacity: .8 !important

    &::after
      backdrop-filter: blur(10px)
      content: ''
      z-index: 1
      position: absolute
      bottom: 0
      width: 100%
      transition: height 1s
      height: 0%
    .box
      margin: auto
      text-align: center
      background: rgba(255,255 ,255 ,0.4 )
      position: relative
      z-index: 3
      border-radius: .5rem
      width: 90%
      backdrop-filter: blur(10px)
    &::before
      transition-property: background
      transition-duration: 1s
      transition-delay: .5s
      transition-timing-function: ease-out
      position: absolute
      content: ' '
      top: 0
      backdrop-filter: blur(10px)
      border-radius: 5px
      height: 100%
      width: 100%
      opacity: 0.4
    .label
      z-index: 2
      position: relative
      margin-top: 130px
      font-weight: bold
      margin-right: 4rem
      font-size: 1.3rem
      padding: 1rem
      &:after
        content: ' '
        height: 3px
        width: 80%
        left: 10%
        right: 10%
        bottom: 0
        position: absolute
        transition: width 1s
    p
      margin-top: 5px
      z-index: 3 !important
      position: relative
      padding: .5rem
      font-weight: 600
      color: #745d48
      font-size: 1rem
    &.amarelo
      &::after
        background: rgb(224,206,43)
        background: linear-gradient(142deg, rgba(224,206,43,.8) 0%, rgba(215,127,40,.8) 100%)
      .label
        color: rgba(215,127,40,1) !important
        &:after
          background: rgb(224,206,43)
          background: linear-gradient(142deg, rgba(224,206,43,.8) 0%, rgba(215,127,40,.8) 100%)
        color: #fff
      &:hover
        .label , p
          color: #fff !important
        &::before
          opacity: .2
          background: rgb(252,227,0)
          background: linear-gradient(142deg, rgba(252,227,0,.8) 0%, rgba(252,125,0,.8) 100%)
          transition: 500ms linear
    &.verde
      &::after
        background: rgb(55,198,74)
        background: linear-gradient(142deg, rgba(55,198,74,.7) 0%, rgba(30,130,38,.8) 100%)
      .label
        color: rgba(30,130,38,1)  !important
        &:after
          mix-blend-mode: hard-light
          background: rgb(55,198,74)
          background: linear-gradient(142deg, rgba(55,198,74,.7) 0%, rgba(30,130,38,.8) 100%)
        color: #fff
      &:hover
        .label , p
          color: #fff !important
        &::before
          opacity: .2
          background: rgb(252,227,0)
          background: linear-gradient(142deg, rgba(25,242,61,.7) 0%, rgba(62,94,47,.8) 100%)
