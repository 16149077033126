
.canais
  width: 90px
  right: 2rem
  position: fixed
  flex-direction: column
  bottom: 2rem
  display: flex
  z-index: 999
  .gatilho
    cursor: pointer !important
    margin: auto
    background: #fc7d00
    background: -moz-linear-gradient(45deg,  #fc7d00 0%, #fc7d00 59%, #fc9500 77%, #f7c88a 100%)
    background: -webkit-linear-gradient(45deg,  #fc7d00 0%,#fc7d00 59%,#fc9500 77%,#f7c88a 100%)
    background: linear-gradient(45deg,  #fc7d00 0%,#fc7d00 59%,#fc9500 77%,#f7c88a 100%)
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fc7d00', endColorstr='#f7c88a',GradientType=1 )
    clear: both
    margin-top: 0.5rem
    box-shadow: 0 5px 1rem 0 #fc7d00
    height: 60px
    width: 60px
    color: #fff
    display: flex
    border-radius: 999px
    img
      height: 25px !important
      margin: auto
  .items
    display: flex
    margin: auto
    flex-direction: column 
    overflow: hidden !important
    max-height:  300px
    transition: max-height .5s ease-in-out !important
    .icone
      height: 65px
      border-radius: 999rem
      margin: 0.3rem
      width: 65px
      background: #FFF
      box-shadow: 0 .2rem 0.5rem 0 rgba(0,0,0,0.2)
    &.hide
      max-height: 0
      pointer-events: none
    .icone
        display: flex !important
        cursor: pointer !important
    img
      height: 25px !important
      margin: auto
      filter: invert(27%) sepia(51%) saturate(2878%) hue-rotate(346deg) brightness(104%) contrast(97%)
    .icon
      font-size: 2rem
      margin: auto
      line-height: 25px
      color: #fba31c