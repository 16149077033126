
.filiais
    flex-wrap: wrap
    .background
         background: #fafafa
    .endereco
        padding: 2rem 1rem 0
        div
            display: flex
            padding: 0 !important
            list-style: none
        h2.header
          padding-bottom: 1rem
          padding: 0
          color: #353b34
          font-size: 1.8rem
          font-weight: bolder !important
        div
          font-size: .9rem !important
          a:hover
              background: #fc7d00 !important
              color: #fff !important
          a
              margin: 0.2rem
              cursor: pointer
              font-weight: 600
              color:  rgba(0,0,0,.87)
              display: block
              border-radius: 58rem
              padding: .7rem 1rem .7rem!important
              &.active
                background: #fc7d00 !important
                color: #fff !important
    .map
        position: relative
        height: 400px
        width: 100%
        clear: both
        margin: auto
        padding: 0 1rem
.subfilial
  display: block
  width: 100%
  margin-right: 11rem !important
  clear: both
  span
    font-size: 0.8rem
    padding: .7rem 1rem
    margin: .5rem .3rem
    cursor: pointer
    border-radius: 5px
    &:hover
      background: rgba(0,0,0,0.02)