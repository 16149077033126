
.pandape 
      flex-wrap: wrap
      max-width: 1100px
      border-radius: 1rem
      background: linear-gradient(45deg, #fc7d00 0%, #fc7d00 59%, #fc9500 77%, #fc9500 100%) !important
      margin: 2rem auto
      position: relative
      justify-content: center
      padding: 2rem
      .efeito
        content: ''
        width: 100%
        height: 100%
        top: 0
        border-radius: 1rem
        position: absolute
        background-size: cover
        mix-blend-mode: hard-light
        opacity: .3
        background-position: center
      h2
        color: #fff
        z-index: 7
        margin: 2rem 0
        position: relative
        &:after
          content: ''
          height: 80%
          width: 5px
          background: #fff
          position: absolute
          left: -2rem
          border-radius: 1rem
          top: 0
      .button
        z-index: 7
        color: #fc9500
        border-radius: 2rem
        box-shadow: 0 .3rem 1rem 0 rgba(0,0,0,0.2)
        background: #fff
        margin: auto 2rem
        font-size: 1.3rem
        &:hover 
          box-shadow: 0 .3rem 1rem 0 rgba(0,0,0,0.2)
          background: linear-gradient(45deg, #fc7d00 0%, #fc7d00 59%, #fc9500 77%, #fc9500 100%) !important
          color: #fff

        