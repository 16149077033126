.compliance
    .ui.header
      color: #fd7e01
      padding:  0 !important
      margin: 0 !important
    img
      height:  220px
      display: block !important
      margin: 1rem 0
      top: 0
      object-fit: cover
      object-position: 60% 60%
      border-radius: 10px
      position: relative
    .efeito
      position: relative
      &:after
        width: 100% !important
        z-index: 1 !important
        mix-blend-mode: overlay
        opacity: .9
        top: 0 !important
        position: absolute
        border-radius: 10px
        height: 100% !important
        content: ''
        background: #fc7d00 !important
        background: linear-gradient(45deg, #fc7d00 0%, #fc7d00 59%, #fcb500 77%, #fcb500 100%) !important
    p
      margin: 0.5rem 0