.contato
    h4
      margin: 0 !important
      a
        font-size: 1.2rem !important
        font-weight: bold !important
    .msg
        padding: 2rem
        font-size: 1.5rem
        line-height: 2rem
        color: #fff
        margin: 0 2rem 2rem 0
        position: relative
        background: #fc7d00 !important
        background: linear-gradient(45deg, #fc7d00 0%, #fc7d00 59%, #fc9500 77%, #fc9500 100%) !important
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fc7d00', endColorstr='#fc9500',GradientType=1 ) !important
        &:before
          content: ''
          z-index: -1
          width: 100%
          height: 100%
          top: -1rem
          right: -1rem
          position: absolute
    input, select, textarea
      border-radius: 2rem !important
      padding: .8rem !important
      outline: none
    .ui.button
      border-radius: 2rem
      padding: 1.2rem
      margin-right: .5rem
      background: rgba(0,0,0,0)
      border-radius: 4rem
      padding: 1rem 1.5rem !important
      border: 1px solid #ff8e00
      background: #fff !important
      color: #fc7d00 !important
      border: 1px solid #ff8e00
    .p-2
      padding: 0 2rem 0 0