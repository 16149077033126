
.sobre
  text-align: justify-all
  &.flex
    display: flex
    flex-wrap: wrap
    .imagem
      order: 2 !important
      width: 38%
      margin: 1%
      height: 320px
      background-size: cover !important
      background-position: 50% !important
      border-radius: 0 1rem
      margin-left: auto
    .texto
      width: 50%
      @media (max-width: 1250px)
        width: 100%
    .ui.header
      padding: 0 1rem
    .quemsomos
      font-size: 1.1rem
      text-align: justify
      padding: 0 1rem
    .sub.header
      font-size: 1.8rem !important
      padding: 1rem
      line-height: 2rem
    .content
      color: #ff9209
      font-size: 3rem
      font-weight: bold !important
  .imagem
    position: relative
    width: 100%
    height: 350px
    background-size: cover
    background-position: center
    margin: 2rem auto
    border-radius: 0 1rem
    &:before
      z-index: 1
      width: 100%
      height: 50px
      background: #ff9209
      content: ' '
      position: absolute
      opacity: 0.7
      bottom: 1rem
      mix-blend-mode: hard-light
      right: -.7rem