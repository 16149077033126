
.maps
  .header
    margin: .5rem 0
    .content
    padding: 0 .5rem .5rem
    .icon
      color: #fc9500
    &> .icon + .content
      padding-left: 0.2rem
    p
      font-weight: normal
      color: rgba(0,0,0,0.5)
      font-size: .9rem !important
      padding-left: .7rem
.filial
  margin-top: 1rem !important
  background: rgba(0,0,0,.05) !important
  .menu
    padding: 0 !important
.box-tel
  background: rgba(0,0,0,0.01)
  padding: 1rem
  height: calc((100% - 30px) / 2)
  margin: 1rem auto
  width: 100%
  clear: both
  border-radius: 0.5rem
  .telefone
    font-weight: bold
    color: #70786f
  .nome
    padding: 0
    margin: 0
    border-bottom: 1px solid #fff
.telefones
  padding: 2rem
.telefones_contato
  display: flex
  justify-content: space-between
  width: 100%
  flex-wrap: wrap !important