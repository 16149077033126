
.bar
  position: relative
  background-position: center
  margin: auto
  background-size: cover
  &:after
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAGCAYAAADgzO9IAAAAI0lEQVQYV2NkwAEYCUq0t7cbgxRVVlaeBdFwHTgl0I3EaQcAR0EIB7IMQI0AAAAASUVORK5CYII=)
    width: 100%
    height: 100%
    opacity: .6
    content: ''
    bottom: 0
    position: absolute
  &.bottom
    background-position: bottom
  &.center
    background-position: center
  &.top
    background-position: top
  &:before
    position: absolute
    border-radius: 1rem !important
    width: 100%
    mix-blend-mode: hard-light
    height: 100%
    content: ''
    bottom: 0
    opacity: .9
  .ui.container
    position: relative
    z-index: 9
    padding: 1rem
    margin: 1rem 0
    .sub
        color: rgb(122, 150, 101) !important
    .icon
        color: #ff8e00
  .ui.header
    background: rgba(255,255,255,1)
    padding: 1rem
    border-radius: 5px !important
    margin: 2rem auto 2rem
    .content
      font-size: 2rem
      color: #ff8e00 !important
      .sub.header
        font-size: 1.2rem

  .pipes
    border-radius: 5px !important
    background: #fff
    padding: 1rem
    margin: -1rem 0 1rem